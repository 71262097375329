.App { 
  display: flex;
}

.fugro-content-body {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 1vh;
}

.fugro-content-body .fugro-content-body-spaced {
  display: flex;
  height: 98vh;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.fugro-content-body .fugro-content-body-spaced .fugro-executions-content {
  display: flex;
  flex-direction: column;
  max-height: 60%;
  overflow-y: scroll;
}

.fugro-content-body  .fugro-content-body-spaced .fugro-executions-content:last-child {
  max-height: 100%;
}

.fugro-content-body  .fugro-content-body-spaced  .fugro-execution-content {
  display: flex;
  flex-direction: column;
  height: 375px;
  flex-shrink: 0;
  overflow-y: scroll;
}

.fugro-content-body  .fugro-content-body-spaced  .fugro-executions-content .fugro-executions-execution-table {
  flex-shrink: 0;
}

.fugro-content-body .fugro-selects {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fugro-content-body .fugro-selects > * {
  margin-right: 1em;
}

.fugro-content-body .fugro-selects > *:last-child {
  margin-right: 0;
}

.fugro-content-body .fugro-pipeline-publish-search {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.fugro-content-body .fugro-campaign-data-table,
.fugro-content-body .fugro-campaign-data-table th,
.fugro-content-body .fugro-campaign-data-table td,
.fugro-content-body .fugro-publish-events-table,
.fugro-content-body .fugro-publish-events-table th,
.fugro-content-body .fugro-publish-events-table td {
  border: 1px solid black;
}

.fugro-content-body .fugro-campaign-data-table,
.fugro-content-body .fugro-publish-events-table {
  border-collapse: collapse;
}

.fugro-content-body .fugro-campaign-data-table th,
.fugro-content-body .fugro-campaign-data-table td {
  width: 33%;
}

.fugro-content-body .MuiAutocomplete-option.fugro-autocomplete-option {
  justify-content: space-between;
}

.MuiTableCell-root.MuiTableCell-head {
  font-weight: bold;
}

.MuiTableRow-root .MuiTableCell-root {
  padding: .5em;
}

.MuiTableRow-root .MuiTableCell-root.table-cell-closed {
  padding: 0;
}


.fugro-content-body .fugro-execution-io,
.fugro-content-body .fugro-execution-details {
  display: flex;
  flex-direction: row;
}

.fugro-content-body .fugro-execution-io>*,
.fugro-content-body .fugro-execution-details>* {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.fugro-content-body .fugro-code {
  white-space: pre-wrap;
  word-break: break-all;
  min-height: 1em;
}